<template>
  <div>
    <div>
      <a-row>
        <a-col :span="12">
          <a-form-item label="推送方式">
            <span>{{ listData.pushType == 'manual' ? '手动推送' : '定时推送'}}</span>
          </a-form-item>
        </a-col>
        <a-col v-if="listData.pushTo == 'userPush'" :span="12">
          <a-form-item label="推送用户">
            <span>{{ listData.userType == 'allUser' ? '全部用户' : '指定用户'}}</span>
          </a-form-item>
        </a-col>
        <a-col v-if="listData.pushTo == 'orgPush'" :span="12">
          <a-form-item label="推送机构">
            <span>{{ listData.userType == 'allOrg' ? '全部机构' : '指定机构'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="消息类型">
            <span>{{ listData.msgType == 'push' ? 'push' : '站内信'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="消息标题">
            <span>{{ listData.title || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="listData.msgType =='website'">
        <a-col :span="24">
          <a-form-item label="摘要">
            <span>{{ listData.summary || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="listData.msgType =='push'">
        <a-col :span="24">
          <a-form-item label="消息内容">
            <span>{{ listData.content || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-else>
        <a-col :span="24">
          <a-form-item label="消息内容">
            <p style="width: 100%;" v-html="listData.content"></p>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="listData.msgType =='push'">
        <a-col :span="24">
          <a-form-item label="落地页链接">
            <span>{{ listData.landUrl || '-'}}</span>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { get } from "@/utils/http";
import TablePage from "@/components/TablePage";
import { customerStage, intentionLevel, customerStatus, starRating } from '@/utils/public';
import dayjs from "dayjs";
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "listDetail",
  data() {
    return {
      detailLoading: false,
      listData: {},
    }
  },
  created() {
    this.getDetail(this.selectedRows[0].id)
  },
  methods: {
    async getDetail(id) {
      this.detailLoading = true;
      try {
        let res = await get("/message/manualPush/getDetailById", { id: id })
        this.listData = res.source;

      } catch (e) {
        this.$message.error(e.message)
      }
      this.detailLoading = false;
    },

  }
}
</script>

<style scoped>
.detail-title {
  font-weight: 550;
}

.detail-text {}

.ant-col {
  margin-top: 20px;
}

.user_image {
  max-width: 224px;
  max-height: 126px;
}
</style>
